.dd-collapse-text-container
    overflow: hidden
    position: relative
    max-height: 3em

    .dd-blurring
        position: absolute
        height: 1.2em
        background: linear-gradient(0deg, rgb(255, 255, 255), rgba(255, 255, 255, 0))
        width: 100%
        bottom: 0px
    
    .dd-close, .dd-blurring
        text-align: center
        color: $darkGreen
        cursor: pointer