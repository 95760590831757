$shadow: 1px 3px 3px 0 rgba(0,0,0,.2), 1px 3px 15px 2px rgba(0,0,0,.2)
$transition: opacity ease-out 300ms, color ease-out 600ms

.dd-layout-container

    .dd-layout-image
        height: 15em
        cursor: pointer
        opacity: .6
        transition: $transition

        &:hover, &.selected
            opacity: 1
            box-shadow: $shadow
    
    .dd-cover-image
        height: 7em
        width: 12em
        object-fit: cover
        opacity: .6
        cursor: pointer
        transition: $transition
        border-radius: .28571429rem

        &:hover, &.selected
            opacity: 1
            box-shadow: $shadow

    .dd-upload
        width: 100%
        height: 7em
        text-align: center
        opacity: .6
        cursor: pointer
        transition: $transition
        display: grid
        align-items: center
        justify-content: center

        &:not(.dd-nohover):hover, &.selected
            opacity: 1
            box-shadow: $shadow

            i.icon
                color: $darkGreen

        i.icon
            display: block
            width: auto
            color: $lightGreen

    .dd-logo
        height: 20em
        display: grid
        align-items: center
        justify-content: center

        i
            opacity: .6

        img
            opacity: 1
            height: 18em
            width: 100%
            object-fit: contain
    
.dd-color-picker
    display: flex
    
    .ui.input
        flex: 1

    .dd-color
        width: 10em
        height: 3em 
        margin-left: 1em
        border-radius: .28571429rem
        border: 1px solid rgba(34,36,38,.15)
        
            