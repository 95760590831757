.dd-answer-state
    text-align: right

    strong
        vertical-align: text-bottom

    .dd-state-notAnswered
        color: rgb(191, 42, 57)

    .dd-state-inProgress
        color: rgb(212, 161, 63)

    .dd-state-answered
        color: rgb(140, 183, 28)

    .dd-state-notAnswered, .dd-state-inProgress, .dd-state-answered
        opacity: .3
        margin-left: .5em
        cursor: pointer
        font-size: 1.2em

        &.dd-selected
            opacity: 1
