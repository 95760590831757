
@font-face
    font-family: "nunito"
    src: url('/assets/fonts/Nunito-Bold.ttf') format('truetype')
    font-display: auto
    font-style: normal
    font-weight: 700

@font-face
    font-family: "nunito"
    src: url('/assets/fonts/Nunito-Regular.ttf') format('truetype')
    font-display: auto
    font-style: normal
    font-weight: 400

@font-face
    font-family: "nunito"
    src: url('/assets/fonts/Nunito-Light.ttf') format('truetype')
    font-display: auto
    font-style: normal
    font-weight: 200