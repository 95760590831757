.dd-compact-grid
    display: flex

    .dd-first-column
        flex: 1
        max-width: 8em

    .dd-arrow-column
        width: 3em

    .dd-second-column
        flex: 1
        max-width: 12em

    .dd-grid-separator
        height: 1em
