.dd-action-link
    cursor: pointer
    color: $darkGreen

    i
        color: $darkGreen

    &:hover
        color: $veryDarkGrey

        i
            color: $veryDarkGrey

    &.dd-multi
        white-space: normal 
        display: flex
        align-items: center
        justify-content: center

        i
            height: auto
    
    &.dd-disabled
        color: $lightGrey
        cursor: inherit
        i
            color: $lightGrey
        &:hover
            color: $lightGrey