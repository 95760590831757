.dd-layout
    display: flex
    max-width: 115em
    margin: auto

    .dd-mainContent
        flex: 1
        margin-right: 1em
        min-width: 0
        margin-bottom: 1em

        .dd-topBar
            display: flex
            padding: 1em 0 1em 1em

            .dd-enviroment-notice
                flex: 1
                color: red
                font-size: 2em
                font-weight: bold
                align-self: center
                font-family: sans-serif
                text-align: center

    .dd-sideContent
        margin: 1em
        margin-left: 0
        padding: 1em
        border-right: 1px solid $darkGrey
        width: 16em
        text-align: center

        .dd-fixed
            position: fixed
            bottom: 1em
            width: 14em

            @media screen and(max-height: 57rem)
                position: inherit
                margin-top: 1em

            .dd-download
                a
                    color: $darkGreen
                    &:hover
                        color: $lightGreen

            .dd-version
                color: inherit

        img
            width: 100%
            margin-top: 3em

            @media screen and (max-width: 75rem), (max-height: 57rem)
                margin-top: 1em
                height: 20rem
