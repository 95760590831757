$lightGrey: rgba(34,36,38,.15)
$darkGrey: #e8e8e8
$greyHover: #e0e0e0
$veryDarkGrey: #666
$lightGreen: #A2C036
$darkGreen: #4D6013
$veryLightGrey: rgba(101, 101, 101, 0.04)

$borderRadius: 5px

.dd-dotdotdot, .ui.multiple.dropdown .ui.label.dd-dotdotdot
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden

.ui.input.dd-sub-input input
    border-radius: 0

.ui.compact.search.selection.dropdown.dd-sub-select
    min-width: 10rem

.ui.form .dd-disabled-light:disabled
    color: $lightGrey

.compact.button.dd-see-button
    margin-left: 2em
    vertical-align: middle

.dd-centered-meta
    display: flex
    align-items: center

    i.icon
        height: auto

.ui.segment:not(.dd-preserve-pre-line), .dd-with-preline
    white-space: pre-line

.dd-placeholder-segment-container
    margin-top: 1rem

    .dd-placeholder-segment
        &:nth-child(1)
            opacity: 1

        &:nth-child(2)
            opacity: 0.9

        &:nth-child(3)
            opacity: 0.8

        &:nth-child(4)
            opacity: 0.7

        &:nth-child(5)
            opacity: 0.6

        &:nth-child(6)
            opacity: 0.5

        &:nth-child(7)
            opacity: 0.4

        &:nth-child(8)
            opacity: 0.3

        &:nth-child(9)
            opacity: 0.2

        &:nth-child(10)
            opacity: 0.1

div.dd-rich-text-editor
    font-family: inherit

    div[class*='ButtonWrap__root']
        z-index: inherit

    div[class*='InputPopover__root___']
        z-index: 2

    div[class*='__editor___']
        font-size: 1em



.dd-filters
        margin-right: 2em