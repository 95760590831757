.dd-question-content
    .ui.form textarea
        border: 0.1em solid rgba(34,36,38,.15)
        box-shadow: 0 0.1em 0.2em 0 rgba(34,36,38,.15)

    .ui.animated.button .visible.content, .ui.animated.button .hidden.content
        transition-delay: 1s

    .ui.form .field
        white-space: pre-line

        .ui.segment
            margin: 0

    .ui.form .field.dd-connected-question
        &:not(:nth-child(2))
            margin-top: 2em

        label.dd-spaced-label
            margin-top: 1em

    .dd-question-saving
        float: right

        .ui.loader
            margin: 0 .3em 0 1em

    .ui.form .field.dd-previous-answer-content-container label
        margin-top: 1em
