.dd-userMenu
    display: inline-block
    padding: 1em 0 1em 1em
    border-bottom: 1px solid $darkGrey

    .dd-text
        margin-right: 1em
        display: inline-block
        
    .dd-container
        display: inline-block