.dd-navigation-button
    margin-top: 1em

    .ui.button
        font-weight: bold
        border: 1px solid $darkGrey
        background-color: white

        &:hover
            background-color: lighten($lightGreen, 45%)

        &.dd-selected
            background-color: $lightGreen
            color: white