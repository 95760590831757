.dd-graphic-section
  display: flex
  align-items: center
  
  .dd-graphic
    flex: 1

  .dd-legend
    margin-left: 3em

.dd-graphic-container 
    shape-rendering: geometricPrecision
    width: 100%
    max-height: 50em
    transform: rotate(60deg)

    .dd-part, .dd-part-container
      transform-origin: center
      shape-rendering: geometricPrecision
      stroke-linecap: round
      stroke-linejoin: round
      stroke-miterlimit: 1.5
      stroke-width: 0.1em

    .dd-part-container
      fill: transparent
      stroke: $lightGrey

    .dd-part
      stroke: transparent

    .dd-center
      transform-origin: center

.dd-item
  display: flex
  align-items: center
  margin-bottom: 1em

  .dd-legend-image
    shape-rendering: geometricPrecision
    stroke-linecap: round
    stroke-linejoin: round
    stroke-miterlimit: 1.5
    height: 5em
    width: 5em
    stroke: $lightGrey
    stroke-width: .1em
  
  .ui.header
    margin: 0 0 0 1em