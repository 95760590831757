.dd-question-meta

    .dd-version
        color: inherit
        text-align: right

    .ui.segments .ui.header
        font-weight: 400

    .accordion.ui

        &.styled
            border: 0.1em solid rgba(34,36,38,.15)
            box-shadow: 0 0.1em 0.2em 0 rgba(34,36,38,.15)

        .title .ui.header
            font-weight: 400

        .title
            display: flex
            align-items: center

            .ui.header
                flex: 1
                margin: 0

            img
                width: 1.6em
                margin: -0.2em .5em 0 0

.ui.label.dd-category-label
    color: white
    display: block
    font-size: 1.1em
    font-weight: normal

    &.dd-no-color
        color: inherit
        background-color: white
        border: .05em solid rgba(34,36,38,.15)
        box-shadow: 0 1px 2px 0 rgba(34,36,38,.15)

    &.dd-navigator
        cursor: pointer
        padding: 1.3em 1em 1em 1em

    i.icon
        margin: 0 .3em

.dd-question-navigator
    min-height: 40em

    .dd-navigator-group

        .dd-question
            cursor: pointer
            display: flex

            div:first-child
                flex: 1
