.dd-star-list-item
    .dd-item-title
        font-weight: bold
        font-size: 1.3em
        vertical-align: middle

    hr
        opacity: .3

    .ui.menu
        flex-wrap: wrap

.dd-item-status-label
    width: 100%
    text-align: center
